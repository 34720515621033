import React from 'react'

const Footer = () => (
  <footer>
    <span>
      © 2021 Created by <a href="https://joemonk.co.uk">Joe Monk</a>
      <br />
      Built with <a href="https://www.gatsbyjs.org">Gatsby</a>
    </span>
  </footer>
)
export default Footer
