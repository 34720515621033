import React from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars-2'

const barsHeight = 146
  
// Resize code from https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
export default function Scroller({ children }) {
  const [dimensions, setDimensions] = React.useState({
    height: typeof window !== `undefined` ? window.innerHeight : 0,
    width: typeof window !== `undefined` ? window.innerWidth : 0,
  })
  
  const [resized, setResized] = React.useState(false)
  
  function handleResize() {
    setDimensions({
      height: typeof window !== `undefined` ? window.innerHeight : 0,
      width: typeof window !== `undefined` ? window.innerWidth : 0,
    })
  }
    
  function handleRender() {
    handleResize()
  }

  React.useEffect(() => {
    if (!resized) {
      setResized(true)
      handleRender()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })


  return (
    <Scrollbars
      universal
      thumbMinSize={50}
      autoHeight
      autoHeightMin={dimensions.height - barsHeight}
      autoHeightMax={dimensions.height - barsHeight} 
      onUpdate={handleRender}
      renderThumbVertical={props => <div {...props} className="thumb-vertical" />}
    >
      {children}
    </Scrollbars>
  )
}

Scroller.propTypes = {
  children: PropTypes.object,
}
