// extracted by mini-css-extract-plugin
export var mobileMenuContainer = "menu-module--mobileMenuContainer--1sq3i";
export var desktopMenuContainer = "menu-module--desktopMenuContainer--1b1e3";
export var menu = "menu-module--menu--3ukrn";
export var menuTrigger = "menu-module--menuTrigger--6orQW";
export var menuActive = "menu-module--menuActive--5VKyI";
export var mobileMenu = "menu-module--mobileMenu--2ml_g";
export var mobileMenuOverlay = "menu-module--mobileMenuOverlay--TaEc3";
export var themeToggle = "menu-module--themeToggle--2_FQ_";
export var subMenuTrigger = "menu-module--subMenuTrigger--1068I";
export var subMenu = "menu-module--subMenu--1fFpT";
export var subMenuOverlay = "menu-module--subMenuOverlay--aQ54c";
export var menuArrow = "menu-module--menuArrow--3s2c9";