import React from 'react'
import PropTypes from 'prop-types'
import { MDXProvider } from '@mdx-js/react'

import { post, postContent } from '../styles/post.module.css'

const PostContent = ({ children }) => (
  <div className={post}>
    <div className={postContent}>
      <MDXProvider>
        {children}
      </MDXProvider>
    </div>
  </div>
)

PostContent.propTypes = {
  children:  PropTypes.array,
}

export default PostContent
