import { graphql, Link } from 'gatsby';
import SEO from "../../../../src/components/seo";
import Layout from "../../../../src/components/layout";
import CV from "../../../../src/components/cv";
import { SizeMe } from 'react-sizeme';
import * as style from "../../../../src/styles/navigation.module.css";
import * as React from 'react';
export default {
  graphql,
  Link,
  SEO,
  Layout,
  CV,
  SizeMe,
  style,
  React
};