import React from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

const CV = ({ size }: { size: { width: number } }) => {
  console.log(size)
  return (
    <Document file={"/Joe%20Monk%20CV.pdf"}>
      <Page width={size.width} pageNumber={1} />
    </Document>
  )
}
export default CV;
