import React from 'react'
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const PosImageStyle = styled.section`
    margin: 6px ${props => props.float === 'left' ? 16 : 0}px 8px ${props => props.float === 'right' ? 0 : 16}px;
    max-width: ${props => props.width};
    float: ${props => props.float};
    width: ${props => props.width};
`;

/**
 * @example
 * <PosImage width='50%' float='left' src='new-portfolio/new-portfolio_old-portfolio.png' alt='new-portfolio_old-portfolio.png'/>
 * 
 * @param param0 
 */
const PosImage = ({ src, alt, float, width }: {src: string, alt: string, float: string, width: string }) => {
    const allImageFiles = useStaticQuery(graphql`
    query allImages {
        allFile(filter: {ext: {regex: "/(png|jpg)/"}}) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData
              }
              relativePath
            }
          }
        }
      }
    `);
      
    const imageFile = allImageFiles.allFile.edges.find((edge) => {
        return edge.node.relativePath === src;
    });
    return (
        <PosImageStyle float={float} width={width}>
            <GatsbyImage image={getImage(imageFile.node)} alt={alt}/>
        </PosImageStyle>
    )
  }

export default PosImage;