import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import CookieConsent from 'react-cookie-consent'
import { useLocation } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

import Header from './header'
import Footer from './footer'
import Scroller from './scroller'

import '../styles/layout.css'

const Layout = ({ children }) => {
  const data = useStaticQuery<GatsbyTypes.SiteTitleQueryQuery>(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          logo {
            src
            alt
          }
          logoText
          mainMenu {
            title
            path
          }
          showMenuItems
          menuMoreText
        }
      }
    }
  `)
  const {
    logo,
    logoText,
    mainMenu,
    showMenuItems,
    menuMoreText,
  } = data.site.siteMetadata
  
  const location = useLocation()

  return (
    <div className="container">
      <Header
        siteLogo={logo}
        logoText={logoText}
        mainMenu={mainMenu}
        mainMenuItems={showMenuItems}
        menuMoreText={menuMoreText}
      />

      <Scroller>
        <div className="content">
          {children}
        </div>
      </Scroller>
      
      <CookieConsent
        cookieName="gatsby-gdpr-google-analytics"
        onAccept={() => {
        initializeAndTrack(location)
        }}

        buttonText="Accept"
        enableDeclineButton
        declineButtonText="Decline"
        flipButtons
        
        location="top"
        disableStyles
        containerClasses="cookieConsentContainer"
        buttonClasses="cookieConsentButton cookieConsentAcceptButton"
        declineButtonClasses="cookieConsentButton cookieConsentDeclineButton"
        contentClasses="cookieConsentContent"
    >
        <div>
          This website uses basic anonymized cookies to track page views on this site only.
        </div>
        <div>
          Enabling them helps me see how people view my site.
        </div>
      </CookieConsent>

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
